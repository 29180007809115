export const errors = {
    required: 'SSN Required',
    minLength: 'Please enter valid SSN number',
    maxlength: 'Please enter valid SSN number',
    invalid: 'Please enter valid SSN number',
    invalidFull: 'Please enter valid SSN number',
    invalidFullNoDash: 'Please enter valid SSN number',
    invalidLast: 'Please enter valid last four digits from SSN number',
    fullError: 'Please insert full SSN number.',
    fullNoDashError: 'Please insert full SSN number.',
    lastError: 'Please enter only the last 4 digits of your SSN.'
};
