import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {UtilsService} from '../../services/utils/utils.service';
import * as moment from 'moment/moment'

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit, OnChanges {

  @Input() date;
  @Input() showTime = false;
  @Input() before = '';
  @Input() after = '';
  protected dateString;
  private nowFormat = true;

  constructor(
      private utilsService: UtilsService
  ) { }

  ngOnInit() {}

  ngOnChanges() {
    this.setDateString();
  }

  private setDateString() {
    if (this.nowFormat) {
      const nowDate = new Date();
      const testDate = new Date(this.date);
      this.dateString = this.utilsService.capitalize(this.utilsService.timeAgo.format(testDate));
      if (this.before && testDate < nowDate) {
        this.dateString = this.before + ' ' + this.dateString;
      }
      if (this.after && testDate > nowDate) {
        this.dateString = this.after + ' ' + this.dateString;
      }
    } else {
      this.dateString = this.showTime ? moment(this.date).format('MMMM Do YYYY, h:mm:ss a') : moment(this.date).format('MMMM Do YYYY');
    }
  }

  clicked() {
    this.nowFormat = !this.nowFormat;
    this.setDateString();
  }

}
