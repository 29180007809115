import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FieldbaseComponent} from '../fieldbase/fieldbase.component';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-get-date',
  templateUrl: './get-date.component.html',
  styleUrls: ['./get-date.component.scss'],
})
export class GetDateComponent extends FieldbaseComponent implements OnInit, AfterViewInit {

  @Input() title: string;
  @Input() name: string;
  @Input() future = false;

  protected datePickerOptions: any = {
    fromDate: null,
    toDate: null,
    showTodayButton: false, // default true
    closeOnSelect: false, // default false
    mondayFirst: true, // default false
    titleLabel: this.title,
    dateFormat: 'MM-DD-YYYY', // default DD MMM YYYY
    clearButton : false , // default true
    yearInAscending: true, // Default false
    btnCloseSetInReverse: false,
    closeLabel: 'Cancel'
  };

  constructor(
      private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this.future) {
      this.datePickerOptions.fromDate = new Date();
    } else {
      this.datePickerOptions.toDate = new Date();
    }
  }

}
