import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UsersService} from '../../services/users/users.service';
import {NotificationsService} from '../../services/notifications/notifications.service';

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss']
})
export class OurlyUserIconComponent implements OnInit {

  @ViewChild('avatar') avatar;

  protected badge = 0;

  constructor(
      private router: Router,
      private usersService: UsersService,
      private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.usersService.meSubscribe((response) => {
      if (response) {
        this.avatar = this.usersService.getAvatar(response.user, this.avatar.nativeElement);
      }
    });
    this.notificationsService.notificationsCountSubscribe((response) => {
      if (response) {
        this.badge = response.newNotificationsCount;
      }
    });
  }

  iconClicked() {
    this.router.navigate([ '/Profile' ]);
  }
}
