import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {UsersService} from '../users/users.service';
import {BehaviorSubject} from 'rxjs';
import {UtilsService} from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  NOTIFICATION_UPDATE_TIME = 10000;

  private notificationsCountObservable: BehaviorSubject<any>;
  private notificationsObservable: BehaviorSubject<any>;

  constructor(
      private restangular: Restangular,
      private usersService: UsersService,
      private utilsService: UtilsService
  ) {
    this.notificationsCountObservable = new BehaviorSubject(null);
    this.notificationsObservable = new BehaviorSubject(null);
  }

  public getNotificationSettings(): Promise<any> {
    const me = this.usersService.me();
    return this.restangular.one('users', me.user.id).one('notifications-settings').get().toPromise();
  }

  public notificationsCountSubscribe(response) {
    this.updateNotificationsCount();
    this.notificationsCountObservable.subscribe(response);
  }

  public notificationsSubscribe(response) {
    this.updateNotifications();
    this.notificationsObservable.subscribe(response);
  }

  public reloadNotifications() {
    this.updateNotificationsCount();
  }

  public notificationOpened(id: string): Promise<any> {
    const me = this.usersService.me();
    return this.restangular.one('users', me.user.id).one('notifications', id).one('status').one('opened').put().toPromise();
  }

  private updateNotificationsCount() {
    const _this = this;
    const me = this.usersService.me();
    if (me) {
      _this.restangular.one('users', me.user.id).one('notifications-details').get().toPromise().then((data) => {
        data.user.avatar = this.utilsService.cacheBustUrl(data.user.avatar);
        _this.notificationsCountObservable.next(data);
      }).catch((error) => {
      });
    }
  }

  private dateToString(date: Date): string {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
        .toISOString()
        .split('T')[0];
  }

  updateNotifications() {
    const _this = this;
    const me = this.usersService.me();
    if (me) {
      const startAt = new Date();
      startAt.setDate(startAt.getDate() - 14);
      _this.restangular.one('users', me.user.id).one('notifications')
       .get({ beginAt: this.dateToString(startAt), endAt: this.dateToString(new Date())}).toPromise().
        then((data) => {_this.notificationsObservable.next(data);
      }).catch((error) => {
      });
    }
  }

}
