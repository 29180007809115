import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import * as moment from 'moment/moment';
import {errors} from './datepicker-errors';
import {FormfieldbaseComponent} from '../formfieldbase/formfieldbase.component';

let futureDates = false;

export function ValidateDate(control: AbstractControl) {
  const today = moment().format('MMM DD, YYYY');
  if (control.value === '') { return null; } // date is not mandatory
  const controlDate = moment(control.value, 'MMM DD, YYYY').format('MMM DD, YYYY');
  // const currentDay = getCurrentDayTimestamp();
    if (!moment(controlDate).isValid()) {
      return { invalidDate: true };
    }
    if (moment(controlDate).isValid() && moment(controlDate) < moment(today) && futureDates) {
      return { invalidDate: true };
    }
    if (moment(controlDate).isValid() && moment(controlDate) > moment(today) && !futureDates) {
      return { invalidDate: true };
    }
  return null;
}

export function ValidateDateRequired(control: AbstractControl) {
  const today = moment().format('MMM DD, YYYY');
  if (control.value === '') { return { invalidDate: true }; } // date is mandatory
  const controlDate = moment(control.value, 'MMM DD, YYYY').format('MMM DD, YYYY');
  // const currentDay = getCurrentDayTimestamp();
    if (!moment(controlDate).isValid()) {
      return { invalidDate: true };
    }
    if (moment(controlDate).isValid() && moment(controlDate) < moment(today) && futureDates) {
      return { invalidDate: true };
    }
    if (moment(controlDate).isValid() && moment(controlDate) > moment(today) && !futureDates) {
      return { invalidDate: true };
    }
  return null;
}

@Component({
  selector: 'app-datepicker-field',
  templateUrl: './datepicker-field.component.html',
  styleUrls: ['../formfieldbase/formfieldbase.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => DatepickerFieldComponent),
     multi: true
   }
 ]
})

export class DatepickerFieldComponent extends FormfieldbaseComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  @Input() title: string;
  @Input() name: string;
  @Input() future = false;
  @Input() initDay: string;
  @Input() requiredDp = false;

  protected datePickerOptions: any = {
    inputDate: moment().format('MMM DD, YYYY'),
    fromDate: null,
    toDate: null,
    showTodayButton: false, // default true
    closeOnSelect: false, // default false
    mondayFirst: true, // default false
    titleLabel: this.title,
    dateFormat: 'MMM DD, YYYY', // default DD MMM YYYY
    clearButton : false , // default true
    yearInAscending: true, // Default false
    btnCloseSetInReverse: false,
    closeLabel: 'Cancel'
  };

  validate(c: AbstractControl): ValidationErrors | null {
    const controls = this.fieldForm.controls;
      Object.keys(controls).forEach(
        (controlName) => {
          if (controls[controlName].errors !== null) {
            this.required = controls[controlName].errors.hasOwnProperty('required');
            this.invalid = controls[controlName].errors.hasOwnProperty('invalidDate');
          } else {
            this.required = false;
            this.invalid = false;
          }
        });
       let message = '';
       if (this.required) { message = errors.required; }
       if (this.invalid) { message = errors.invalid; }
       if (this.invalid && this.required) { message = errors.required; }
       this.errorMessage = message;
    return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
  }
  constructor(private cdRef: ChangeDetectorRef) {
    super('dp');
    this.fieldForm = new FormGroup(
      {
        dp: new FormControl('')
      });
   }

  ngOnInit() {
  }

  testDate(strDate: string) {
    const formats = ['YYYY-MM-DD'],
        valid = moment.utc(strDate, formats).isValid();
        if (valid) {
          return true;
        }
        return false;
  }

  setFuture() {
    if (this.future) {
      this.datePickerOptions.fromDate = moment().format('MMM DD, YYYY');
      this.datePickerOptions.toDate = moment('2050-12-31', 'YYYY-MM-DD').format('MMM DD, YYYY');
      futureDates = true;
    } else {
      this.datePickerOptions.fromDate = moment('1920-01-01', 'YYYY-MM-DD').format('MMM DD, YYYY');
      this.datePickerOptions.toDate = moment().format('MMM DD, YYYY');
      futureDates = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.requiredDp && changes.requiredDp.isFirstChange() && this.requiredDp) {
      this.fieldForm.get(this.thisFieldName).setValidators([
        Validators.required,
        ValidateDateRequired
      ]);
      this.setFuture();
      this.setValue('');
    }
    if (changes.requiredDp && changes.requiredDp.isFirstChange() && !this.requiredDp) {
      this.fieldForm.get(this.thisFieldName).setValidators([
      ValidateDate
      ]);
      this.setFuture();
      this.setValue('');
    }
    if (this.testDate(changes.initDay.currentValue)) {
      this.datePickerOptions.inputDate = moment(changes.initDay.currentValue, 'YYYY-MM-DD').format('MMM DD, YYYY');
      this.setValue(moment(changes.initDay.currentValue, 'YYYY-MM-DD').format('MMM DD, YYYY'));
      if (this.fieldForm.invalid) {
        this.fieldForm.controls[this.thisFieldName].markAsDirty();
        this.fieldForm.controls[this.thisFieldName].markAsTouched();
      }
    }
  }

  dateFocusInput(event) {
    this.focusInput(event);
    this.setFuture();
  }

}
