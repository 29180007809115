import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';

import { tap } from 'rxjs/operators';

import { HttpCacheService } from '../services/http-cache/http-cache.service';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CachingInterceptor implements HttpInterceptor {
    constructor(private cache: HttpCacheService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const cachedResponse = this.cache.get(req);
        return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next, this.cache);
    }

    sendRequest(
        req: HttpRequest<any>,
        next: HttpHandler,
        cache: HttpCacheService): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    cache.put(req, event);
                }
            })
        );
    }
}
