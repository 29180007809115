import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class CommissionJunctionService {

  private cachedCJOffers = [];

  constructor(
      private restangular: Restangular
  ) { }

  productSearch(keywords: string, advertisers: string = ''): Promise<any> {
    return this.restangular.one('v3').one('commissionjunction').one('search').get({ keywords: keywords ? keywords : '', advertisers: advertisers ? advertisers : ''}).toPromise().then(results => {
      this.cachedCJOffers = this.cachedCJOffers.concat(results);
      return results;
    });
  }

  getCJRebateDetails(id: string) {
    return this.cachedCJOffers.find(offer => id === offer.sku);
  }

  async getCLODetails(id: string) {
    if (this.cachedCJOffers.length === 0) {
      await this.productSearch('');
    }
    return this.cachedCJOffers.find(offer => id === offer.id);
  }

  getDefaultCashBack() {
    return 0.04;
  }

  getAdvertisers() {
    return this.restangular.one('v3').one('commissionjunction').getList('advertisers').toPromise();
  }

}
